import {
	CompleteRegistration,
	Data,
	InitiateCheckout,
	Purchase,
} from "react-facebook-pixel";
import cookieBanner from "~/data/cookie_banner";

interface Instance {
	track: (event: string, data: Data) => void;
	trackCustom: (event: string, data: Data) => void;
	pageView: () => void;
}

class Facebook {
	instance?: any;
	initialized = false;
	queue: Array<() => void> = [];
	granted = false;

	init(): Promise<Instance | undefined> {
		return new Promise((resolve) => {
			if (typeof window == "undefined" || this.initialized)
				return resolve(this.instance);

			import("react-facebook-pixel")
				.then((x) => x.default)
				.then((ReactPixel) => {
					const options = {
						autoConfig: true,
						debug: true,
					};
					ReactPixel.init(import.meta.env.VITE_FB, undefined, options);
					if (!cookieBanner.isActionComplete()) {
						ReactPixel.revokeConsent();
					}

					this.instance = ReactPixel;
					this.initialized = true;

					this.queue.forEach((task) => task());
					this.queue = [];

					resolve(this.instance);
				});
		});
	}

	private enqueueTask(task: () => void) {
		if (this.initialized && this.instance) {
			task();
		} else {
			this.queue.push(task);
		}
	}

	trackPurchase(data: Purchase) {
		this.enqueueTask(() => {
			this.instance?.track("Purchase", data);
		});
	}

	trackCompleteRegistration(data: CompleteRegistration) {
		this.enqueueTask(() => {
			this.instance?.track("CompleteRegistration", data);
		});
	}

	trackInitiateCheckout(data: InitiateCheckout) {
		this.enqueueTask(() => {
			this.instance?.track("InitiateCheckout", data);
		});
	}

	trackCustom(event: string, data: Data) {
		this.enqueueTask(() => {
			this.instance?.trackCustom(event, data);
		});
	}

	trackPageView() {
		this.enqueueTask(() => {
			this.instance?.pageView();
		});
	}

	grandContest() {
		if (this.granted) return;
		this.granted = true;

		console.log("GRANT CONSENT");
		this.enqueueTask(() => {
			this.instance.grantConsent();
		});
	}
}

const fb = new Facebook();

export default fb;

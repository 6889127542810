import { v4 as uuidv4 } from "uuid";
import amp from "./amplitude";
import { trackingApi } from "./api/tracking_api";
import storage, { StorageKeys } from "./local_storage";
import { TAllQuestion } from "~/data/entity";
import fb from "~/data/facebook";

class Track {
	session_number = 0;
	trackIndexPageView = false;

	constructor() {
		const session = Number(storage.get(StorageKeys.sessionNumber)) ?? 0;
		this.session_number = session + 1;
		storage.set(StorageKeys.sessionNumber, this.session_number.toString());
	}

	track(eventName: string, params?: Record<string, any>) {
		const data = {
			...this.getBaseParams(),
			eventProperties: this.prepareProperties({
				...(params ?? {}),
				web_funnel_user_id: trackingApi.webFunnelUserId,
				funnel_id: trackingApi.funnelId,
				ab_test_name: [],
				ab_test_group: [],
			}),
			eventType: eventName,
		};
		console.log("PUB/SUB: ", eventName, data);
		trackingApi.track(data);
		amp.track(eventName, data);
	}

	trackAnswer({
		answerId,
		question,
		location,
		firstQuestionId,
	}: {
		answerId: number | number[] | null;
		question: TAllQuestion;
		location: string;
		firstQuestionId: number;
	}) {
		this.track("question_answered", {
			question_id: question?.trackId || question?.id,
			answer_id: answerId,
			location: location,
		});

		if (question?.id === firstQuestionId) {
			this.track("quiz_start_click");
			fb.trackCustom("quiz_start_click", {});
		}
	}

	trackAttribution() {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		this.track("attribution_event", {
			media_source: urlParams.get("utm_source"),
			campaign_name: urlParams.get("utm_campaign"),
			adset_id: urlParams.get("adset_id"),
			campaign_id: urlParams.get("campaign"),
			adset_name: urlParams.get("adset_name"),
			ad_name: urlParams.get("ad_name"),
			ad_id: urlParams.get("ad_id"),
		});
	}

	private getBaseParams() {
		const d = new Date();
		return {
			clientUploadTime: d.toISOString(),
			clientEventTime: d.toISOString(),
			language: "US",
			deviceType: this.getDeviceType(),
			platform: navigator.platform,
			os: this.getOS(),
			versionName: this.getBrowserVersion(),
			insertId: uuidv4(),
			sessionId: this.session_number,
		};
	}

	private getDeviceType() {
		const ua = navigator.userAgent;
		if (/Mobi|Android/i.test(ua)) {
			return "mobile";
		}
		return "desktop";
	}

	private getOS() {
		const ua = navigator.userAgent;
		if (/Windows NT/i.test(ua)) return "Windows";
		if (/Mac OS X/i.test(ua)) return "MacOS";
		if (/Linux/i.test(ua)) return "Linux";
		if (/Android/i.test(ua)) return "Android";
		if (/iPhone|iPad|iPod/i.test(ua)) return "iOS";
		return "unknown";
	}

	private getBrowserVersion() {
		const ua = navigator.userAgent;
		const browserMatch =
			ua.match(
				/(firefox|msie|chrome|safari|opr|edg|edge(?=\/))\/?\s*(\d+)/i
			) || [];
		return browserMatch[2] || "unknown";
	}

	private prepareProperties(params: Record<string, any>) {
		for (const key in params) {
			if (
				Object.prototype.hasOwnProperty.call(params, key) &&
				params[key] != null
			) {
				params[key] = String(params[key]);
			}
		}
		return params;
	}
}

const tracking = new Track();

export default tracking;

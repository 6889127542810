import * as amplitude from "@amplitude/analytics-browser";
import { trackingApi } from "../api/tracking_api";
import { SuccessMessage } from "@solidgate/react-sdk";
import { autocapturePlugin } from "@amplitude/plugin-autocapture-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { ValidPropertyType } from "@amplitude/analytics-types";

class Amplitude {
	initialized = false;

	init() {
		if (typeof window == "undefined" || this.initialized) return;
		this.initialized = true;

		console.log("INIT AMPLITUDE");
		amplitude.init(
			import.meta.env.VITE_AMPLITUDE,
			trackingApi.webFunnelUserId,
			{
				autocapture: {
					formInteractions: false,
					fileDownloads: false,
				},
			}
		);
		const autoCapture = autocapturePlugin();
		const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 1 });
		amplitude.add(autoCapture);
		amplitude.add(sessionReplayTracking);

		this.setUserProperties("funnel_id", trackingApi.funnelId);
		this.setUserProperties("ab_test_name", []);
		this.setUserProperties("ab_test_group", []);
	}

	track(eventName: string, params: Record<string, any>) {
		amplitude.track(eventName, params).promise.then(() => {
			console.log("amplitude", eventName, params);
		});
	}

	trackRevenue(data: SuccessMessage, productId: string) {
		const event = new amplitude.Revenue()
			.setProductId(productId)
			.setPrice((data.order.amount ?? 0) / 100)
			.setQuantity(1);

		amplitude.revenue(event).promise.then((result) => {
			console.log(result);
		});
	}

	setUserProperties(key: string, val: ValidPropertyType) {
		const identifyEvent = new amplitude.Identify();
		identifyEvent.set(key, val);
		amplitude.identify(identifyEvent);
	}
}

const amp = new Amplitude();

export default amp;

import fb from "../facebook";
import { ICkyConsent } from "./entity";
import amp from "~/data/amplitude";
import tracking from "~/data/tracking";

class CookieBanner {
	trackCookieAccept = false;

	init() {
		this.checkCurrentStatus();

		document?.addEventListener(
			"cookieyes_consent_update",
			() => {
				this.checkCurrentStatus();
			}
		);

		// @ts-ignore
		document?.addEventListener("cookieyes_banner_load", (data: CustomEvent<ICkyConsent>) => {
			const info = data.detail;
			if (!info.isUserActionCompleted) {
				tracking.track("cookie_open");
			}
			this.checkCurrentStatus();
		});
	}

	isActionComplete() {
		const data = this.getCkyConsent();
		return data?.isUserActionCompleted;
	}

	private getCkyConsent(): ICkyConsent | undefined {
		// @ts-ignore
		return window?.getCkyConsent?.();
	}

	private checkCurrentStatus() {
		const data = this.getCkyConsent();

		if (data?.isUserActionCompleted) {
			fb.grandContest();
		} else {
			this.removeCkyHideClass();
		}

		if (data?.categories.analytics === true && !this.trackCookieAccept) {
			this.trackCookieAccept = true;
			tracking.track("cookie_accept");
			amp.init();

		}
	}



	private removeCkyHideClass() {
		const overlay = document.querySelector(".cky-overlay");

		if (overlay && overlay.classList.contains("cky-hide")) {
			overlay.classList.remove("cky-hide");
		}
	}
}

const cookieBanner = new CookieBanner();

export default cookieBanner;
